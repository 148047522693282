import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem, darken } from 'polished';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

import { Link } from 'gatsby';

import { colors } from '../../theme';

const TopItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: stretch;
`;

const ItemLink = styled(Link)`
  flex-grow: 1;
  flex-shrink: 1;
  padding: ${(props) => props.theme.components.drawer.item.padding};
  color: ${({ theme, color }) =>
    color === 'negative'
      ? theme.components.menu.item.colorNegative
      : theme.components.menu.item.color};
  font-weight: ${(props) =>
    props.theme.components.menu.itemVertical.fontWeight};
  font-size: ${(props) => props.theme.components.menu.itemVertical.fontSize};
  letter-spacing: ${(props) =>
    props.theme.components.menu.itemVertical.letterSpacing};
  text-transform: ${(props) =>
    props.theme.components.menu.itemVertical.textTransform};

  &:hover {
    color: ${({ theme, color }) =>
      color === 'negative'
        ? theme.components.menu.item.hoverNegative
        : theme.components.menu.item.hover};
  }

  &.active {
    color: ${(props) => props.theme.palette.colors.primary};
  }
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: ${rem(18)};
  vertical-align: middle;
  margin-left: 0.5em;
  margin-top: -0.1em;
`;

const Toggle = styled.div`
  cursor: pointer;
  flex-grow: 0;
  flex-shrink: 0;
  padding: ${(props) => props.theme.components.drawer.item.padding};
  padding-left: 0.5rem;
  border-left: 1px solid
    ${(props) => darken(0.1, props.theme.components.drawer.background)};

  &.active {
    ${Icon} {
      transform: rotate(180deg);
    }
  }
`;

const SubMenu = styled.div`
  display: none;
  flex-direction: column;
  position: relative;
  top: 100%;
  left: 0;
  background-color: ${(props) => props.theme.components.drawer.background};
  min-width: ${rem(190)};
  font-size: 0.85em;
  margin-bottom: 1rem;

  /* visibility: hidden; */

  &.active {
    display: flex;
    /* visibility: visible; */
  }
`;

const SubItemLink = styled(ItemLink)`
  text-transform: none;
  padding-top: 0.4em;
  padding-bottom: 0.4em;
`;

const Item = styled.div`
  position: relative;

  .active {
    /* background: ${(props) => props.theme.palette.colors.greyLight}; */
    color: ${(props) => props.theme.palette.colors.primary};
  }
`;

function AccordionItem(props) {
  const [isActive, setActive] = useState(false);
  const { item } = props;
  const { children } = item;

  function handleToggle() {
    setActive(!isActive);
  }

  return (
    <Item className={isActive && 'active'}>
      <TopItem className={isActive && 'active'}>
        <ItemLink
          to={item.link}
          activeClassName="active"
          partiallyActive={false}
        >
          {item.label}
        </ItemLink>
        {children && (
          <Toggle className={isActive && 'active'}>
            <Icon
              icon={faChevronDown}
              color={colors.primary}
              onClick={handleToggle}
            />
          </Toggle>
        )}
      </TopItem>
      <SubMenu className={isActive && 'active'}>
        {children?.map((child) => {
          return (
            <SubItemLink
              key={child.label}
              to={child.link}
              activeClassName="active"
              partiallyActive={false}
            >
              {child.label}
            </SubItemLink>
          );
        })}
      </SubMenu>
    </Item>
  );
}

AccordionItem.propTypes = {
  item: PropTypes.shape({
    link: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    children: PropTypes.arrayOf(
      PropTypes.shape({
        link: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
      }),
    ),
  }).isRequired,
};

export default AccordionItem;
