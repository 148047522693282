import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem, darken } from 'polished';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

// const Root = styled.div`
//   position: fixed;
//   left: 0;
//   top: 0;
//   width: 100%;
//   height: 100%;
//   overflow: hidden;
//   z-index: 11;
//   -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

//   /* font-weight: ${(props) => props.theme.typography.weights.heading.bold}; */
//   /* pointer-events: none; */
// `;

const Overlay = styled.div`
  display: block;
  position: fixed;
  z-index: 98;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  will-change: opacity;
  transition: opacity 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);

  ${({ visible }) =>
    visible &&
    `opacity: 1;
    width: 100%;
    height: 100%;`}
`;

const Container = styled.div`
  position: fixed;
  top: 0;
  z-index: 99;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.components.drawer.background};
  height: 100%;
  width: 90%;
  max-width: ${rem(240)};
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  will-change: transform;
  color: ${(props) => props.theme.components.drawer.item.color};

  &:hover {
    color: ${(props) => props.theme.components.drawer.item.hover};
  }

  /* cubic-bezier(0.25, 0.1, 0.25, 1) */

  ${({ fromPos }) => {
    switch (fromPos) {
      case 'left':
      default:
        return `
          transform: translateX(-103%);
          left: 0;`;
      case 'right':
        return `
          transform: translateX(103%);
          right: 0;`;
    }
  }}

  ${({ open }) => open && `transform: none;`}
`;

const Header = styled.header`
  padding: ${(props) => props.theme.components.drawer.padding};
  flex-grow: 0;
  border-bottom: 1px solid
    ${(props) => darken(0.1, props.theme.components.drawer.background)};
`;

const Footer = styled.footer`
  padding: ${(props) => props.theme.components.drawer.padding};
  flex-grow: 0;
`;

const Main = styled.div`
  flex: 1 1;
  padding: ${(props) => props.theme.spacing.columnGutter} 0;
`;

const ToggleButton = styled.button`
  appearance: none;
  background: none;
  border: 0;
  padding: 0.3rem;
  outline: 0;
`;

const CloseIcon = styled(FontAwesomeIcon)`
  font-size: ${rem(24)};
`;

const blockClick = (e) => e.stopPropagation();

/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
function Drawer({ fromPos, toggle, open, children, footer }) {
  return (
    <>
      <Overlay visible={open} onClick={toggle} />
      <Container fromPos={fromPos} open={open} onClick={blockClick}>
        {toggle && (
          <Header>
            <ToggleButton type="button" onClick={toggle}>
              <CloseIcon icon={faTimes} color="#000" />
            </ToggleButton>
          </Header>
        )}
        <Main>{children}</Main>
        {footer && <Footer>{footer}</Footer>}
      </Container>
    </>
  );
}
/* eslint-enable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */

Drawer.propTypes = {
  children: PropTypes.node.isRequired,
  footer: PropTypes.node,
  open: PropTypes.bool,
  fromPos: PropTypes.string,
  toggle: PropTypes.func,
};

Drawer.defaultProps = {
  open: false,
  footer: '',
  fromPos: 'left',
  toggle: () => {},
};

export default Drawer;
