import { graphql } from 'gatsby';

export const Fragment = graphql`
  fragment LinkFragment on PRISMIC__Linkable {
    _linkType
    ... on PRISMIC__ExternalLink {
      _linkType
      url
    }
    ... on PRISMIC__FileLink {
      _linkType
      name
      url
    }
    ... on PRISMIC__ImageLink {
      _linkType
      name
      url
    }
    ... on PRISMIC_Products {
      title
      _linkType
      _meta {
        uid
        type
        lang
      }
    }
    ... on PRISMIC_Product {
      title
      _linkType
      _meta {
        uid
        type
        lang
      }
    }
    ... on PRISMIC_Articles {
      title
      _linkType
      _meta {
        uid
        type
        lang
      }
    }
    ... on PRISMIC_Article {
      title
      _linkType
      _meta {
        uid
        type
        lang
      }
    }
    ... on PRISMIC_Page {
      title
      _linkType
      _meta {
        uid
        type
        lang
      }
    }
    ... on PRISMIC_Projects {
      title
      _linkType
      _meta {
        uid
        type
        lang
      }
    }
    ... on PRISMIC_Project {
      title
      _linkType
      _meta {
        uid
        type
        lang
      }
    }
    ... on PRISMIC_Contact {
      title
      _linkType
      _meta {
        uid
        type
        lang
      }
    }
    ... on PRISMIC_Homepage {
      title
      _linkType
      _meta {
        uid
        type
        lang
      }
    }
  }
`;

export default Fragment;
