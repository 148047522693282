import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'gatsby';
import LinkUtil from '../../utils/link';

function Link({ to, children, ...other }) {
  return typeof to === 'string' && /^https?:\/\//.test(to) ? (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <a href={to} target="_blank" rel="noopener noreferrer" {...other}>
      {other.children}
    </a>
  ) : (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <RouterLink to={LinkUtil.url(to)} {...other}>
      {children}
    </RouterLink>
  );
}

Link.propTypes = {
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.func])
    .isRequired,
  children: PropTypes.node,
};

Link.defaultProps = {
  children: '',
};

export default Link;
