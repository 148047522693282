import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem } from 'polished';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

import { Link } from 'gatsby';

import { colors } from '../../theme';

const ItemLink = styled(Link)`
  color: ${({ theme, color }) =>
    color === 'negative'
      ? theme.components.menu.item.colorNegative
      : theme.components.menu.item.color};
  font-weight: ${(props) => props.theme.components.menu.item.fontWeight};
  font-size: ${(props) => props.theme.components.menu.item.fontSize};
  letter-spacing: ${(props) => props.theme.components.menu.item.letterSpacing};
  text-transform: ${(props) => props.theme.components.menu.item.textTransform};
  padding: ${(props) => props.theme.components.menu.item.padding};

  &:hover {
    color: ${({ theme, color }) =>
      color === 'negative'
        ? theme.components.menu.item.hoverNegative
        : theme.components.menu.item.hover};
  }

  &.active {
    color: ${(props) => props.theme.palette.colors.primary};
  }
`;

const SubMenu = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: ${(props) => props.theme.palette.background.content};
  min-width: ${rem(190)};

  visibility: hidden;

  &.active {
    visibility: visible;
  }
`;

const Item = styled.div`
  /* z-index: 1; */

  &.active {
  position: relative;

    /* z-index: 2; */
  }


  .active {
    /* background: ${(props) => props.theme.palette.colors.greyLight}; */
    color: ${(props) => props.theme.palette.colors.primary};
  }
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: ${rem(18)};
  vertical-align: middle;
  margin-left: 0.5em;
  margin-top: -0.1em;
`;

function DropdownItem(props) {
  const [isActive, setActive] = useState(false);
  const { item, color } = props;
  const { children } = item;

  function handleEnter() {
    setActive(true);
  }

  function handleLeave() {
    setActive(false);
  }

  return (
    <Item
      className={isActive && 'active'}
      onMouseEnter={handleEnter}
      onMouseLeave={handleLeave}
    >
      <ItemLink
        className={isActive && 'active'}
        to={item.link}
        activeClassName="active"
        partiallyActive={false}
        color={color}
      >
        {item.label}
        {children && <Icon icon={faChevronDown} color={colors.primary} />}
      </ItemLink>
      <SubMenu className={isActive && 'active'}>
        {children?.map((child) => {
          return (
            <ItemLink
              key={child.label}
              to={child.link}
              activeClassName="active"
              partiallyActive={false}
              color={color}
            >
              {child.label}
            </ItemLink>
          );
        })}
      </SubMenu>
    </Item>
  );
}

DropdownItem.propTypes = {
  item: PropTypes.shape({
    link: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    children: PropTypes.arrayOf(
      PropTypes.shape({
        link: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
      }),
    ),
  }).isRequired,
  color: PropTypes.oneOf(['default', 'negative', 'inline']),
};

DropdownItem.defaultProps = {
  color: 'default',
};

export default DropdownItem;
