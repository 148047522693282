import React from 'react';

import { StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import logo from '../../images/logo/baker-corp-logo-neg.svg';

// const LogoWrap = styled.div`
//   display: block;
//   width: ${(props) => props.theme.grid.maxWidth};
//   padding: 0 1rem 1.5rem;
// `;

const Logo = styled.img`
  display: block;
  width: 100%;
`;

const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;

function SiteLogo() {
  return (
    <StaticQuery
      query={`${query}`} // String hack needed to fix https://github.com/birkir/gatsby-source-prismic-graphql/issues/70
    >
      {({ site }) => <Logo src={logo} alt={site.siteMetadata.title} />}
    </StaticQuery>
  );
}

export default SiteLogo;
