import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import AccordionItem from './AccordionItem';

const Root = styled.nav`
  /* display: flex;
  align-items: center;
  flex-flow: wrap; */
`;

function AccordionMenu({ items }) {
  return (
    <Root>
      {items.map((item) => {
        return <AccordionItem key={item.label} item={item} />;
      })}
    </Root>
  );
}

AccordionMenu.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default AccordionMenu;
