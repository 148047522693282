/**
 * @see https://github.com/prismicio/prismic-helpers/pull/3
 */

import { linkResolver } from '@theprepgroup/gatsby-source-prismic-graphql';

export default class Link {
  static url(link, resolver = linkResolver) {
    if (
      link &&
      // eslint-disable-next-line no-underscore-dangle
      [link.link_type, link._linkType, link.linkType].some(
        (e) => e && ['Document', 'Link.Document', 'Link.document'].includes(e),
      )
    ) {
      return resolver ? resolver(link) : '';
    }
    if (link) {
      return link.url;
    }
    if (process.env.NODE_ENV !== 'production') {
      // eslint-disable-next-line no-console
      console.warn(
        'PrismicHelpers/Link.url expects a Prismic "link" object as first argument but none was passed',
      );
    }
    return '';
  }
}
