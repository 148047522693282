import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import DropdownItem from './DropdownItem';

const Root = styled.nav`
  display: flex;
  align-items: center;
  flex-flow: wrap;

  ${({ align }) => {
    let alignment = '';

    switch (align) {
      case 'center':
        alignment = 'center';
        break;
      case 'end':
        alignment = 'flex-end';
        break;
      case 'start':
      default:
        alignment = 'flex-start';
        break;
    }

    return `justify-content: ${alignment};`;
  }}
`;

function DropdownMenu({ items, align, color }) {
  return (
    <Root align={align}>
      {items.map((item) => {
        return <DropdownItem key={item.label} item={item} color={color} />;
      })}
    </Root>
  );
}

DropdownMenu.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  align: PropTypes.oneOf(['start', 'center', 'end']),
  color: PropTypes.oneOf(['default', 'negative', 'inline']),
};

DropdownMenu.defaultProps = {
  align: 'start',
  color: 'default',
};

export default DropdownMenu;
