import React from 'react';
import PropTypes from 'prop-types';
import { RichText } from 'prismic-reactjs';
import { rem, math } from 'polished';
import styled from 'styled-components';

import BorderTitle from '../../components/BorderTitle';
import Col from '../../components/Col';
import Image from '../../components/Image';

import { breakpoint } from '../../theme';

// import LinkUtil from '../../utils/link';

const Root = styled.div`
  position: relative;
  display: flex;
  flex-flow: row wrap;
  margin-left: ${(props) => math(`${props.theme.spacing.columnGutter} * -1`)};
  margin-right: ${(props) => math(`${props.theme.spacing.columnGutter} * -1`)};
  /* width: 100%; */
`;

const Item = styled(Col)`
  margin: 0 0 ${rem(20)};

  ${breakpoint('mediumlarge')} {
    width: 50%;
  }
`;

function ImageGallery({ data }) {
  // console.log(data);

  const { fields: images, primary: details } = data;

  if (!images) {
    return null;
  }

  return (
    <Root>
      {details.name_of_the_gallery &&
        RichText.asText(details.name_of_the_gallery) && (
          <Col>
            <BorderTitle as="h4">
              {RichText.asText(details.name_of_the_gallery)}
            </BorderTitle>
          </Col>
        )}
      {images.map(
        (item) =>
          item.gallery_image && (
            <Item key={item.gallery_image.url}>
              <Image
                fluid={item.gallery_imageSharp?.childImageSharp?.fluid}
                fallback={item.gallery_image}
              />
            </Item>
          ),
      )}
    </Root>
  );
}

ImageGallery.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  // content: PropTypes.oneOfType([PropTypes.object]).isRequired,
  // button: PropTypes.shape({
  //   link: PropTypes.object,
  //   label: PropTypes.string,
  // }),
};

export default ImageGallery;
