import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import Sticky from 'react-sticky-el';
import { withPreview } from '@theprepgroup/gatsby-source-prismic-graphql';
import { useTranslation } from 'react-i18next';

import LinkUtil from '../../utils/link';
import LinkFragment from '../../utils/LinkFragment';

import Button from '../Button';
import Header from '../Header';
import Footer from '../Footer';
import SiteLogo from '../SiteLogo';
import Drawer from '../Drawer';
import AccordionMenu from '../AccordionMenu';

const Root = styled.div`
  position: relative;
  /* background-color: ${(props) => props.theme.palette.background.content}; */
`;

const HeaderWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
`;

const StickyWrap = styled(Sticky)`
  transition: box-shadow 0.5s, background-color 0.25s ease-in-out;

  &.stuck {
    background-color: ${(props) => props.theme.palette.background.body};
    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.5);
  }
`;

const Content = styled.div`
  margin: 0 auto;
  background-color: ${(props) => props.theme.palette.background.content};

  /* max-width: ${(props) => props.theme.grid.maxWidth};
  padding: 0 1rem 1.5rem; */
`;

const CtaButton = styled.div`
  padding: ${(props) => props.theme.components.menu.itemVertical.padding};
`;

const query = graphql`
  query {
    site {
      siteMetadata {
        title
        logo
      }
    }
    prismic {
      allMultilevel_navigations {
        edges {
          node {
            body {
              ... on PRISMIC_Multilevel_navigationBody1st_level {
                type
                label
                primary {
                  link_text
                  nav_link {
                    ...LinkFragment
                  }
                }
              }
              ... on PRISMIC_Multilevel_navigationBody2nd_level {
                type
                label
                primary {
                  link_text
                  nav_link {
                    ...LinkFragment
                  }
                }
              }
            }
            _meta {
              uid
              lang
            }
          }
        }
      }
      allSettingss {
        edges {
          node {
            topbar_cta_label
            topbar_cta_link {
              ...LinkFragment
            }
            _meta {
              lang
            }
          }
        }
      }
    }
  }
`;

function formatNavigations(navigations, lang) {
  const menus = navigations.edges.reduce((navs, { node: nav }) => {
    // eslint-disable-next-line no-underscore-dangle
    if (lang !== nav._meta.lang.slice(0, 2)) {
      return navs;
    }

    const navigation = nav.body.reduce((items, item, index) => {
      if (item.type !== '1st_level') {
        return items;
      }

      const children = [];

      nav.body.slice(index + 1).some((childItem) => {
        if (childItem.type === '1st_level') {
          return true;
        }

        children.push({
          label: childItem.primary.link_text,
          link: LinkUtil.url(childItem.primary.nav_link),
        });

        return false;
      });

      return [
        ...items,
        {
          label: item.primary.link_text,
          link: LinkUtil.url(item.primary.nav_link),
          children: children.length === 0 ? null : children,
        },
      ];
    }, []);

    return {
      ...navs,
      // eslint-disable-next-line no-underscore-dangle
      [nav._meta.uid]: navigation,
    };
  }, {});

  return menus;
}

const Layout = ({ children }) => {
  const { i18n } = useTranslation();
  const [drawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setIsDrawerOpen(!drawerOpen);
  };

  return (
    <StaticQuery
      query={`${query}`} // String hack needed to fix https://github.com/birkir/gatsby-source-prismic-graphql/issues/70
    >
      {withPreview(
        ({
          site,
          prismic: {
            allMultilevel_navigations: navigations,
            allSettingss: allSettings,
          },
        }) => {
          const menus = formatNavigations(navigations, i18n.language);

          menus.all = [].concat(...Object.values(menus));

          const settings = allSettings.edges.find(
            // eslint-disable-next-line no-underscore-dangle
            ({ node }) => i18n.language === node._meta.lang.slice(0, 2),
          );

          // CTA - button
          const ctaButton = {
            label: settings?.node.topbar_cta_label, // eslint-disable-line camelcase
            link: settings?.node.topbar_cta_link, // eslint-disable-line camelcase
          };

          return (
            <Root>
              <Drawer open={drawerOpen} toggle={toggleDrawer}>
                <AccordionMenu items={menus.all} direction="vertical" />
                {ctaButton.link !== undefined && (
                  <CtaButton>
                    <Button
                      link={ctaButton.link}
                      variant="hollow"
                      appearance="negative"
                      style={{ color: '#000' }}
                    >
                      {ctaButton.label}
                    </Button>
                  </CtaButton>
                )}
              </Drawer>

              <HeaderWrap>
                <StickyWrap stickyClassName="stuck">
                  <Header
                    siteTitle={site.siteMetadata.title}
                    logo={<SiteLogo />}
                    drawerToggle={toggleDrawer}
                    navigationStart={menus['main-nav-left']}
                    navigationEnd={menus['main-nav-right']}
                    ctaButton={ctaButton}
                  />
                </StickyWrap>
              </HeaderWrap>

              <Content>
                <main>{children}</main>
              </Content>

              <Footer
                siteTitle={site.siteMetadata.title}
                logo={<SiteLogo />}
                // navigation={menus.all}
              />
            </Root>
          );
        },
        query,
        [LinkFragment],
      )}
    </StaticQuery>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
