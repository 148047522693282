import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { useTranslation } from 'react-i18next';

import svFlag from './flags/se.svg';
import gbFlag from './flags/gb.svg';

import { breakpoint } from '../../theme';

const Switcher = styled.div`
  display: flex;
  align-items: center;

  ${breakpoint('large')} {
    /* padding: ${(props) => props.theme.spacing.gutter} 0; */
  }
`;

const LanguageItem = styled.button`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.palette.text.primary};
  padding: 0.65rem;
  appearance: none;
  outline: none;
  border: 0;
  line-height: 1;
  font-size: ${rem(17)};
  cursor: pointer;
  opacity: 0.4;
  background: none;

  &:hover {
    opacity: 1;
  }

  ${({ active }) => active && `opacity: 1;`}
`;

const LanguageIcon = styled.div`
  display: block;
  border-radius: 3px;
  overflow: hidden;
  width: ${rem(20)};
  height: ${rem(20)};
  margin-right: ${rem(9)};
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
`;

function LanguageSwitcher() {
  const { t, i18n } = useTranslation();

  const languages = [
    {
      code: 'en',
      label: 'En',
      name: t('english'),
      image: gbFlag,
    },
    {
      code: 'sv',
      label: 'Sv',
      name: t('swedish'),
      image: svFlag,
    },
  ];

  function handleChangeLanguage(lang) {
    i18n.changeLanguage(lang);
  }

  return (
    <Switcher>
      {languages.map((lang) => (
        <LanguageItem
          type="button"
          key={lang.code}
          onClick={() => handleChangeLanguage(lang.code)}
          active={lang.code === i18n.language}
        >
          <LanguageIcon
            style={{ backgroundImage: `url(${lang.image})` }}
            title={lang.name}
          />
          {lang.label}
        </LanguageItem>
      ))}
    </Switcher>
  );
}

export default LanguageSwitcher;
