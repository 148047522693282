import React from 'react';
import PropTypes from 'prop-types';
import { RichText } from 'prismic-reactjs';
import { linkResolver } from '@theprepgroup/gatsby-source-prismic-graphql';
import { rem } from 'polished';

import styled from 'styled-components';

import Button from '../../components/Button';

import { breakpoint } from '../../theme';

// import LinkUtil from '../../utils/link';

const Root = styled.div`
  position: relative;
  width: 100%;
`;

const Content = styled.div`
  max-width: 100%;
  width: 100%;
  margin: auto;

  ${breakpoint('medium')} {
    max-width: 82%;
  }

  p {
    color: ${(props) => props.theme.palette.colors.greyLight};
  }
`;

const Actions = styled.div`
  display: block;
  margin-top: ${rem(30)};
`;

function ColumnWithButton({ data }) {
  if (!data.primary.content) {
    return null;
  }

  return (
    <Root>
      <Content>
        <RichText render={data.primary.content} linkResolver={linkResolver} />
      </Content>
      {data.primary.button_link && (
        <Actions>
          <Button link={data.primary.button_link} variant="hollow">
            {data.primary.button_label}
          </Button>
        </Actions>
      )}
    </Root>
  );
}

ColumnWithButton.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  // content: PropTypes.oneOfType([PropTypes.object]).isRequired,
  // button: PropTypes.shape({
  //   link: PropTypes.object,
  //   label: PropTypes.string,
  // }),
};

export default ColumnWithButton;
