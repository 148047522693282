import React from 'react';
import PropTypes from 'prop-types';
import { RichText } from 'prismic-reactjs';
import { rem } from 'polished';

import styled from 'styled-components';

const Root = styled.h3`
  display: block;
  width: 100%;
  position: relative;
  text-align: center;
  margin: 0 auto 2.125rem auto;
  font-size: ${rem(28)};

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -1px;
    width: 100%;
    height: 2px;
    background-color: ${(props) => props.theme.global.border.color};
  }
`;

const Inner = styled.span`
  position: relative;
  padding: 0 ${rem(42)};
  background-color: ${(props) => props.theme.palette.background.content};
`;

function BorderTitle({ children }) {
  return (
    <Root>
      <Inner>
        {typeof children === 'string' ? children : RichText.asText(children)}
      </Inner>
    </Root>
  );
}

BorderTitle.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.string]).isRequired,
};

export default BorderTitle;
